import React, {Component} from 'react'
import {base} from '../../base';
import appBackgroundImage from '../../styles/images/trivia_background.png';
// import sampleScoreboardTopImage from '../../styles/images/top_scoreboard.png';
// import sampleScoreboardBottomImage from '../../styles/images/bottom_scoreboard.png';
// import sampleAppTopImage from '../../styles/images/top_icon.png'
import '../../styles/css/CreatorAwards.css'
import AnswerItemsComponent from "../small_components/AnswerItemsComponent";
import posed, {PoseGroup} from "react-pose";

const Trans = posed.div({
    enter: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: ({ delta }) => - delta * 100 + 'vw' }
})

class SideBigScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            frozenVotingState: [],
            answers: [],
            questions: [],
            question: 0,
            currentGame: {},
            isVisible: false,
            rotate: false,
            questionAnswered: false,
            userAnswers: [],
            startAnimating: true
            // triviaGameOn: false,
        };
    }

    componentDidMount() {
        this.currentGameRef = base.bindToState('currentGame', {
          context: this,
          state: 'currentGame',
        })
        this.questionAnsweredRef = base.bindToState('currentGame/answered', {
          context: this,
          state: 'questionAnswered',
        })

        this.questionTextRef = base.bindToState(`currentGame/questions`, {
          context: this,
          state: 'questions'
        });

        this.userAnswersRef = base.bindToState(`userAnswers`, {
            context: this,
            state: 'userAnswers',
            asArray: true
        });
        this.gameTimingRef = base.bindToState('currentGame/gameTiming', {
            context: this,
            state: 'gameTiming',
        })
    }

    setUpQuestionsWithAnswerCount(userAnswers, questions){
        let finalQuestionTally = {};
        let questionIdArray = [];
        for(let questionIndex in questions){
            let question = questions[questionIndex];
            let questionId = question.id;
            finalQuestionTally[questionId] = {};
            finalQuestionTally[questionId].totalPicks = 0;
            finalQuestionTally[questionId].answers = {};
            let questionAnswers = question.answers;
            for(let answerIndex in questionAnswers){
                let answer = questionAnswers[answerIndex];
                let answerId = answer.id;
                finalQuestionTally[questionId].answers[answerId] = 0;
            }
            questionIdArray.push(questions[questionIndex].id);
        }
        if(userAnswers.length > 0) {
            for(let answerIndex in userAnswers) {
                let answerObject = userAnswers[answerIndex];
                for(let questionIndex in questionIdArray){
                    let questionId = questionIdArray[questionIndex];
                    if(answerObject[questionId]){
                        finalQuestionTally[questionId].totalPicks++;
                        let answerId =  answerObject[questionId];
                        finalQuestionTally[questionId].answers[answerId]++;
                    };
                }
            }
        }
        return finalQuestionTally;
    }

    handleImageLoaded() {
      setTimeout(() => this.setState({
        isVisible: true
      }), 1);
    }

    handleImageErrored() {
      console.log("Image failed to load")
      this.setState({ loading: false });
    }

    componentWillUnmount() {
        base.removeBinding(this.questionTextRef);
        base.removeBinding(this.questionAnsweredRef);
        base.removeBinding(this.currentGameRef);
        base.removeBinding(this.userAnswersRef);
        base.removeBinding(this.gameTimingRef);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevState.currentGame.questionToShow === this.state.currentGame.questionToShow - 1){
            this.setState({startAnimating:false}, ()=>{
                this.setState({startAnimating:true})
            })
        }
    }

    render() {
        const tenantVariables = this.props.variables || {};
        const currentGame = this.state.currentGame;
        const questions = currentGame.questions || [];
        let show_percentages = false;
        if(tenantVariables.showScoreboardPercentages === "all"){
            show_percentages = true;
        } else if(tenantVariables.showScoreboardPercentages === "parttime" && this.state.questionAnswered) {
            show_percentages = true;
        }
        let questionCount = {};
        let questionLeftText = tenantVariables.leftScoreboardQuestionSize || "44";
        questionLeftText = questionLeftText + "px";
        let currentQuestion = {};
        if(questions.length > 0){
          currentQuestion = questions[currentGame.questionToShow] || questions[0];
          questionCount = this.setUpQuestionsWithAnswerCount(this.state.userAnswers, questions)
        }
        const backgroundImage = tenantVariables.leftScoreboardBackground || appBackgroundImage;
        const leftOfScoreboardImage = tenantVariables.leftOfScoreboardImage || "";
        const isRSLTeam = process.env.REACT_APP_FIREBASE_PROJECT_ID === "rlsprediction";

        return (
            <div className="grid-wrapper" style={{backgroundImage: "url(" + backgroundImage + ")", paddingTop: isRSLTeam && 275, paddingBottom: isRSLTeam && 275}}>
                <div className="grid-container-outer">
                    <div className="grid-left">
                        <div className="creator-awards-logo"><img src={leftOfScoreboardImage} alt=""/></div>
                    </div>
                    <div>
                        <h1 style={{color:tenantVariables.progressBarQuestionTextColor, marginBottom:"30px", fontSize:questionLeftText, fontWeight:700, textShadow: "2px 2px #000"}}>{currentQuestion.questionText}</h1>
                        {this.state.startAnimating &&
                            <PoseGroup animateOnMount={true} delta={1} style={{margin:"auto"}}>
                                <Trans key={233}>
                                    <AnswerItemsComponent show_percentages={show_percentages} question={currentQuestion} answers={currentQuestion.answers} questionCount={questionCount} screenToShow={currentGame.screenToShow} tenantVariables={tenantVariables} fromScoreboard={true} sideBigScreen={true} onClickAnswer={()=>console.log("click")}/>
                                </Trans>
                            </PoseGroup>
                        }
                    </div>
                </div>
            </div>
          )
        }
}

export default SideBigScreen;
